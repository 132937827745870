import {
  ALL_ORDER_SOURCES,
  ALL_PLATFORMS,
  COLOR_PALETTE,
  PLATFORM_WEBSITE,
  SERVICE_TYPES,
  SERVICE_TYPE_ENUMS,
} from 'src/consts';

const { DELIVERY, DINE_IN, TAKE_OUT, CREDIT_CARD_TERMINAL } = SERVICE_TYPE_ENUMS;
export const OLD_MASTER_CARD_BRAND_NAME = 'MasterCard';
export const NEW_MASTER_CARD_BRAND_NAME = 'Mastercard';
export const BILLING_UNION_PAY_BRAND_NAME = 'UnionPay';
export const DISPLAY_UNION_PAY_BRAND_NAME = 'China UnionPay';
export const BRAND_LIST = [
  'Visa',
  OLD_MASTER_CARD_BRAND_NAME,
  'American Express',
  'Discover',
  BILLING_UNION_PAY_BRAND_NAME,
  'JCB',
  'Diners Club',
  'Cashback',
  'Unknown',
];
export const FEE_TYPES = ['network', 'gateway', 'chowbus'];

const colorMapping = {};

ALL_ORDER_SOURCES.forEach((orderSource, index) => {
  colorMapping[orderSource] = COLOR_PALETTE[index];
});

SERVICE_TYPES.forEach(({ type }, index) => {
  colorMapping[type] = COLOR_PALETTE[index];
});

ALL_PLATFORMS.forEach((platform, index) => {
  colorMapping[platform] = COLOR_PALETTE[index];
});

colorMapping[PLATFORM_WEBSITE] = COLOR_PALETTE[ALL_PLATFORMS.length];

BRAND_LIST.forEach((brand, index) => {
  colorMapping[brand] = COLOR_PALETTE[index];
});

FEE_TYPES.forEach((feeType, index) => {
  colorMapping[feeType] = COLOR_PALETTE[index];
});

export const LEGEND_COLOR_MAPPING = colorMapping;

export const IN_HOUSE_SERVICE_TYPES = [DINE_IN, TAKE_OUT, DELIVERY, CREDIT_CARD_TERMINAL];
export const PAGE_WIDTH = 612;
export const PAGE_HEIGHT = 792;
export const PAGE_FOOTER_HEIGHT = 30;
export const PAGE_PADDING = 16;
export const SECTION_TITLE_HEIGHT = 23;
export const ROW_HEIGHT = 20;
export const SECTION_GAP = 24;
export const TITLE_CONTENT_GAP = 8;
export const TABLE_HEADER_HEIGHT = 22;
export const SECTION_HEADING_HEIGHT = SECTION_TITLE_HEIGHT + TITLE_CONTENT_GAP;
export const WORKSPACE_HEIGHT = PAGE_HEIGHT - PAGE_PADDING - PAGE_FOOTER_HEIGHT;

export const PLATFORM_CHOWBUS = 'Chowbus';
export const PLATFORM_ALL = 'all';
export const PLATFORM_OTHERS = 'others';

export const FIELD_BILL_COUNT = 'bill_count';

export const ACTION_PREVIEW = 'preview';
export const ACTION_DOWNLOAD = 'download';
export const ACTION_UPLOAD = 'upload';
export const ACTION_AUTOMATIC_GENERATING = 'automatic_generating';

export const PAYOUT_SOURCE_TYPE_RESTAURANT_CONTRACT = 'RestaurantContract';
export const PAYOUT_SOURCE_TYPE_MOKOMO = 'Makomo::RestaurantPayoutUpload';
export const PAYOUT_STATE_SETTLED = 'settled';
export const PAYOUT_TYPE_OFFLINE = 'offline';
export const PAYOUT_TYPE_AUTOMATIC = 'automatic_payout';
export const PAYOUT_STATE_DRAFT = 'draft';
export const PAYOUT_STATE_CANCELED = 'canceled';
export const POS_INCOME_TYPE_REBATE = 'pos rebate';
export const POS_INCOME_TYPE_OTHERS = 'pos others';
export const POS_SPECIFIC_TYPE_INCOME = 'pos adjustment income';
export const PAYOUT_PAID_IN_FULL = 'Paid in Full';

export const PRODUCT_LINE_VOUCHER = 'voucher';
export const PRODUCT_LINE_OFFLINE_ADJUSTMENT = 'offline_adjustment';

export const NORMAL_DATE_FORMAT = 'YYYY-MM-DD';
export const COMPACT_DATE_FORMAT = 'MM/DD/YYYY';
